<template>
  <div class="new-lid">
      <div v-loading="is_pending" class="new-lid-wrapper slide-in-top">
          <div class="new-lid-wrapper-header">
              <h2>יצירת ליד חדש</h2>
              <div @click="$emit('close')" class="exit-btn">
                  <i class="material-icons">close</i>
              </div>
          </div>
          <div class="new-lid-wrapper-content">
              <div class="new-lid-wrapper-field">
                  <h2>שם העסק</h2>
                   <el-input v-model="values.business_name" placeholder="שם העסק" />
              </div>
              <div class="new-lid-wrapper-field">
                  <h2>שם המנהל</h2>
                   <el-input v-model="values.manager_name" placeholder="שם המנהל" />
              </div>
              <div class="new-lid-wrapper-field">
                  <h2>טלפון</h2>
                   <el-input v-model="values.phone" type="tel" placeholder="טלפון" />
              </div>
              <div class="new-lid-wrapper-field">
                  <h2>כתובת</h2>
                  <el-input v-model="values.address" placeholder="כתובת" />
              </div>
              <div class="new-lid-wrapper-field">
                  <h2>עיר</h2>
                   <el-input  v-model="values.city" placeholder="עיר" />
              </div>
              <div class="new-lid-wrapper-field">
                  <h2>לטיפול - שם הסוכן</h2>
                    <el-select v-model="values.agent_id" style="width:100%;"  class="m-2" placeholder="בחירת סוכן" size="large">
                        <el-option
                        v-for="agent in agents_list"
                        :key="agent.id"
                        :label="agent.name"
                        :value="agent.id"
                        />
                    </el-select>
              </div>
               <div class="new-lid-wrapper-field">
                  <h2>הערות</h2>
                    <el-input
                        v-model="values.notes"
                        :rows="2"
                        type="textarea"
                        placeholder="הערות"
                    />
              </div>
          </div>
          <div class="new-lid-wrapper-footer">
              <el-button @click="handle_submit" style="width:80%;" type="success">שמירה</el-button>
          </div>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectFirestore } from '../../../firebase/config'
import {slide_pop_error,alert} from '../../../Methods/Msgs'
export default {
    emits:['close','newLid'],
    props:['agent','new_lid'],
    setup(props,{emit}){
        
        const err_msg = ref('')
        const is_pending = ref(false)
        const agents_list = ref([])

        const values = ref({
            created_at:new Date(),
            business_name:'',
            manager_name:'',
            phone:'',
            address:'',
            city:'',
            agent_id:props.agent?props.agent.id:'',
            notes:'',
            status:'open',
        })

        const validation = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'business_name':
                        if(!value){
                            err_msg.value = 'עליך להזין שם העסק'
                            return false
                        }
                        break;
                    case 'manager_name':
                        if(!value){
                            err_msg.value = 'עליך להזין שם מנהל העסק'
                            return false
                        }
                        break;
                    case 'phone':
                        if(!(value.length>=9 && value.length<=10)){
                            err_msg.value = 'עליך להזין טלפון תקין של העסק'
                            return false
                        }
                        break;
                    case 'address':
                        if(value.length<3){
                            err_msg.value = 'עליך להזין כתובת תקינה של העסק'
                            return false
                        }
                        break;
                    case 'city':
                        if(!value){
                            err_msg.value = 'עליך להזין עיר'
                            return false
                        }
                        break;
                    case 'agent_id':
                        if(!value){
                            err_msg.value = 'עליך לבחור סוכן'
                            return false
                        }
                        break;
                }
            }
            return true
        }

        const handle_submit = async()=>{
            if(!validation()){
                slide_pop_error(err_msg.value)
            }else{
                is_pending.value = true
                if(props.new_lid && props.new_lid.uid){
                    await projectFirestore.collection('Agent_portal').doc('Agent_portal')
                    .collection('Lidim').doc(props.new_lid.uid).delete()
                }
                const doc = projectFirestore.collection('Agent_portal').doc('Agent_portal').collection('Lidim').doc()
                values.value.uid = doc.id
                await doc.set(values.value)
                is_pending.value = false
                alert('success','הצלחה',
                'נוצר ליד חדש בהצלחה!')
                .then(()=>{
                    emit('newLid',values.value)
                })
            }
        }
        const init = async()=>{
            const docs = await projectFirestore.collection('Agents').get()
             docs.docs.forEach(doc=>{
                if(doc.data().uid){
                   agents_list.value.push({id:doc.data().id,name:doc.data().name})
                }
            })

            if(props.new_lid){
                values.value = props.new_lid
            }
        }
        

        init()



        return{
            agents_list,
            values,
            handle_submit,
            err_msg,
            is_pending,
        }
    }
}
</script>

<style scoped>
    .new-lid{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 8;
        background: rgba(0, 0, 0, 0.2);
        padding: 0 5px;
    }
    .new-lid-wrapper{
        width: 100%;
        max-width: 500px;
        height: 80%;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
    .new-lid-wrapper-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .exit-btn{
        position: absolute;
        top: 5px;
        left: 5px;
        background: var(--danger);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        z-index: 9;
        cursor: pointer;
        color: #fff;
    }
    .new-lid-wrapper-content{
        width: 100%;
        height: calc(100% - 100px);
        overflow:hidden;
        overflow-y: auto;
    }
    .new-lid-wrapper-footer{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .new-lid-wrapper-field{
        width: 100%;
        height: fit-content;
        margin-top: 5px;
        padding: 0 5px;
    }

    .slide-in-top {
	    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	    animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
                transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    }


</style>