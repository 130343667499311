<template>
  <div class="lidim-manager">
      <div class="lidim-manager-tools">
        <el-input style="width:30%;" v-model="search" placeholder="חיפוש" />
        <el-select style="margin-right:5px;" v-model="filter_by" class="m-2" placeholder="סנן לפי" size="large">
            <el-option
            v-for="option in lids_status_options"
            :key="option.value"
            :label="option.label"
            :value="option.value"
            />
        </el-select>
         <div @click="$emit('close')" class="back-btn">
              <i class="material-icons">arrow_back</i>
          </div>
      </div>
      <div v-if="platform=='desktop' || platform=='tablet'" class="lidim-manager-content">
        <table>
            <tr>
                <th style="cursor:pointer;" @click="handleSortTable('תאריך', !selectedSorted.order)">תאריך</th>
                <th> הלקוח/עסק</th>
                <th> המנהל</th>
                <th>כתובת</th>
                <th>עיר</th>
                <th>טלפון</th>
                <th>הערות</th>
                <th style="cursor:pointer;" @click="handleSortTable('סוכן', !selectedSorted.order)">סוכן</th>
                <th style="cursor:pointer;" @click="handleSortTable('סטטוס', !selectedSorted.order)">סטטוס</th>
                <th>פעולות</th>
                <th></th>
            </tr>
            <h1 v-if="filter_by_status.length==0">לא קיימות רשומות</h1>
            <template v-for="lid in sorted_lids" :key="lid.uid">
                <tr>
                    <td v-if="!lid.created_at.seconds">{{new Date(lid.created_at).toLocaleDateString('he')}}</td>
                    <td v-else>{{new Date(lid.created_at.seconds*1000).toLocaleDateString('he')}}</td>
                    <td>{{lid.business_name}}</td>
                    <td>{{lid.manager_name}}</td>
                    <td>{{lid.address}}</td>
                    <td>{{lid.city}}</td>
                    <td>{{lid.phone}}</td>
                    <td v-if="lid.notes">{{lid.notes}}</td>
                    <td v-else>לא צויינו הערות</td>
                    <td>{{lid.agent_name?lid.agent_name:''}}</td>
                    <td v-if="lid.status=='pending'" style="color:#f5953b;">
                        <p>ממתין לאישור</p>
                        <small style="color:#333;">{{lid.pending_string}}</small>
                    </td>
                    <td v-if="lid.status=='open'" style="color:green;">פתוח</td>
                    <td v-if="lid.status=='close'" style="color:#7367f0;">
                        <p>סגור</p>
                        <small style="color:#333;">{{lid.close_string}}</small>
                    </td>
                    <td v-if="lid.status=='canceled'" style="color:red;">
                        <p>בוטל</p>
                        <small style="color:#333;">{{lid.canceled_string}}</small>
                    </td>
                    <td v-if="lid.status=='e-relevant'" style="color:#63707A">
                        <p>לא רלוונטי</p>
                        <small style="color:#333;">{{lid.e_relevant_string}}</small>
                    </td>
                    <td style="padding:5px;">
                          <el-button style="width:100%;" @click="handle_create_lid(lid)" v-if="lid.status=='canceled'" type="primary">יצירת ליד</el-button>
                          <el-button style="width:100%;" @click="handle_e_relevant_lid(lid)" v-if="lid.status=='close'" type="warning">לא רלוונטי</el-button>
                    </td>
                    <td>
                        <i @click="handle_delete(lid)" class="material-icons delete-icon">delete</i> 
                    </td>
                    <td>
                        <i @click="handle_edit(lid)" class="material-icons edit-icon">edit</i>
                    </td>
                </tr>
            </template> 
        </table>
      </div>
      <div v-if="platform=='mobile'" class="lidim-manager-content">
           <h1 v-if="filter_by_status.length==0">לא קיימות רשומות</h1>
           <template v-for="lid in sorted_lids" :key="lid.uid">
               <table class="mobile-table">
                        <tr>
                            <th style="width:30%">תאריך</th>
                            <td v-if="!lid.created_at.seconds">{{new Date(lid.created_at).toLocaleDateString('he')}}</td>
                            <td v-else>{{new Date(lid.created_at.seconds*1000).toLocaleDateString('he')}}</td>
                        </tr>
                        <tr>
                            <th>הלקוח/עסק</th>
                            <td>{{lid.business_name}}</td>
                        </tr>
                        <tr>
                            <th> המנהל</th>
                            <td>{{lid.manager_name}}</td>
                        </tr>
                        <tr>
                            <th>כתובת</th>
                            <td>{{lid.address}}</td>
                        </tr>
                        <tr>
                            <th>עיר</th>
                            <td>{{lid.city}}</td>
                        </tr>
                        <tr>
                           <th>טלפון</th>
                           <td>{{lid.phone}}</td>
                        </tr>
                        <tr>
                            <th>הערות</th>
                            <td v-if="lid.notes">{{lid.notes}}</td>
                            <td v-else>לא צויינו הערות</td>
                        </tr>
                        <tr>
                            <th> סוכן</th>
                           <td>{{lid.agent_name?lid.agent_name:''}}</td>
                        </tr>
                        <tr>
                            <th>סטטוס</th>
                            <td v-if="lid.status=='pending'" style="color:#f5953b;">
                                <p>ממתין לאישור</p>
                                <small style="color:#333;">{{lid.pending_string}}</small>
                            </td>
                            <td v-if="lid.status=='open'" style="color:green;">פתוח</td>
                            <td v-if="lid.status=='close'" style="color:#7367f0;">
                                <p>סגור</p>
                                <small style="color:#333;">{{lid.close_string}}</small>
                            </td>
                            <td v-if="lid.status=='canceled'" style="color:red;">
                                <p>בוטל</p>
                                <small style="color:#333;">{{lid.canceled_string}}</small>
                            </td>
                            <td v-if="lid.status=='e-relevant'" style="color:#63707A">
                                <p>לא רלוונטי</p>
                                <small style="color:#333;">{{lid.e_relevant_string}}</small>
                            </td>
                        </tr>
                        <tr>
                            <th>פעולות</th>
                             <td>
                                <el-button @click="handle_create_lid(lid)" v-if="lid.status=='canceled'" type="primary">יצירת ליד</el-button>
                                <el-button v-if="lid.status=='close'" type="warning">לא רלוונטי</el-button>
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td><i @click="handle_delete(lid)" class="material-icons delete-icon">delete</i></td>
                        </tr>
                </table>
           </template>
      </div> 
      <div class="lidim-manager-pagination">
        <el-pagination 
             v-model:currentPage="pagination.current_page"
             background 
            :page-size="pagination.number_per_page"
            layout="prev,pager,next"
            :total="filter_by_status.length" 
        />
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectFirestore } from '../../../firebase/config'
import { computed } from '@vue/runtime-core'
import store from '../../../store'
import {alert_confirm} from '../../../Methods/Msgs'
import Swal from 'sweetalert2'
export default {
    emits:['create_new_lid','close','delete'],
    setup(_,{emit}){
        
        const platform = ref(computed(()=>{
            return store.state.platform
        }))
        
        const lidim = ref([])
        const filter_by = ref('all')
        const search = ref('')
        const agents_names = ref([])

        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };
        
        const selectedSorted = ref({
            name: "",
            order: false
        });

        const sorted_lids = ref(computed(() => {
            if (selectedSorted.value.name == "")return slice_lids.value;
            if (selectedSorted.value.name == "תאריך") {
                return slice_lids.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  new Date(a.created_at.seconds*1000) >  new Date(b.created_at.seconds*1000)
                        ? 1
                        : new Date(b.created_at.seconds*1000)  > new Date(a.created_at.seconds*1000)
                        ? -1
                        : 0;
                    }
                    return new Date(a.created_at.seconds*1000) > new Date(b.created_at.seconds*1000) 
                    ? -1
                    : new Date(b.created_at.seconds*1000)  > new Date(a.created_at.seconds*1000)
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "סוכן") {
                return slice_lids.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.agent_name >  b.agent_name
                        ? 1
                        : b.agent_name  > a.agent_name
                        ? -1
                        : 0;
                    }
                    return a.agent_name > b.agent_name
                    ? -1
                    : b.agent_name  > a.agent_name
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "סטטוס") {
                return slice_lids.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.status >  b.status
                        ? 1
                        : b.status  > a.status
                        ? -1
                        : 0;
                    }
                    return a.status > b.status
                    ? -1
                    : b.status  > a.status
                    ? 1
                    : 0;
                });
            }
            
        }));

        const handle_delete = (lid)=>{
            alert_confirm(`למחוק ליד של ${lid.business_name} המקושר לסוכן ${lid.agent_name}?`)
            .then(async res=>{
                if(res.isConfirmed){
                     await projectFirestore.collection('Agent_portal').doc('Agent_portal').collection('Lidim')
                    .doc(lid.uid).delete()
                    const index = lidim.value.findIndex(_lid=>_lid.uid==lid.uid)
                    index!=-1&&lidim.value.splice(index,1)
                    emit('delete',lid)
                }
            })
        }

        const handle_edit = async(lid)=>{
            console.log(lid);
            switch (lid.status) {
                case 'close':
                    await edit_dialog(lid,lid.close_string,'close_string')
                    break;
                case 'pending':
                    await edit_dialog(lid,lid.pending_string,'pending_string')
                    break;
                case 'e-relevant':
                    await edit_dialog(lid,lid.e_relevant_string,'e_relevant_string')
                    break;
            }
        }
        const edit_dialog = async(lid,note,status_string)=>{
            const { value: input } =  await Swal.fire({
                title: 'עריכת הערת סטטוס נוכחי',
                input: 'text',
                inputPlaceholder: note,
                showCancelButton: true,
                confirmButtonText: "המשך",
                cancelButtonText: "סגור", 
                })

                if (input) {
                    await projectFirestore.collection('Agent_portal').doc('Agent_portal').collection('Lidim')
                    .doc(lid.uid).set({
                        [status_string]:input,
                    },{merge:true})
                    
                    const index = lidim.value.findIndex(_lid=>_lid.uid==lid.uid)
                    if(index!=-1){
                        lidim.value[index][status_string] = input
                    }

                }
        
        }

        const handle_e_relevant_lid = async(lid)=>{
            const { value: input } =  await Swal.fire({
                title: 'רשום סיבת אי רלוונטיות',
                input: 'text',
                inputPlaceholder: 'הסיבה היא...',
                showCancelButton: true,
                confirmButtonText: "המשך",
                cancelButtonText: "סגור", 
                })

                if (input) {
                    await projectFirestore.collection('Agent_portal').doc('Agent_portal').collection('Lidim')
                    .doc(lid.uid).set({
                        status:'e-relevant',
                        e_relevant_string:input,
                        canceled_date:new Date()
                    },{merge:true})
                    
                    const index = lidim.value.findIndex(_lid=>_lid.uid==lid.uid)
                    if(index!=-1){
                        lidim.value[index].status = 'e-relevant'
                        lidim.value[index].e_relevant_string = input
                    }

                }
        }
        const handle_create_lid = (lid)=>{
            const copy_lid = JSON.parse(JSON.stringify(lid))
            reset_extansions(copy_lid)
            emit('create_new_lid',copy_lid)
        }
        const lids_status_options = ref([
            {
                value:'all',
                label:'הצג הכל'
            },
            {
                value:'close',
                label:'לידים סגורים'
            },
            {
                value:'open',
                label:'לידים פתוחים'
            },
            {
                value:'pending',
                label:'לידים ממתינים'
            },
            {
                value:'canceled',
                label:'לידים שבוטלו'
            },
            {
                value:'e-relevant',
                label:'לידים לא רלוונטים'
            },
        ])
        
        const pagination = ref({
            current_page:1,
            number_per_page:15,
        })
        
        const filter_by_status = ref(computed(()=>{
            if(filter_by.value == 'all'){
                return filter_by_search.value
            }
            return filter_by_search.value.filter(lid=>lid.status==filter_by.value)
        }))

        const filter_by_search = ref(computed(()=>{
            return lidim.value.filter(lid=>{
                if(new Date(lid.created_at.seconds*1000).toLocaleDateString('he').includes(search.value)) return lid
                if(lid.business_name.includes(search.value)) return lid
                if(lid.manager_name.includes(search.value)) return lid
                if(lid.city.includes(search.value)) return lid
                if(lid.address.includes(search.value)) return lid
                if(lid.agent_name && lid.agent_name.includes(search.value)) return lid
                if(lid.phone.includes(search.value)) return lid
            })
        }))

        const slice_lids = ref(computed(()=>{
            return filter_by_status.value.slice((pagination.value.current_page-1)*pagination.value.number_per_page,
            ((pagination.value.current_page-1)*pagination.value.number_per_page)+pagination.value.number_per_page)
        }))

        const get_agents_names = async()=>{
            const docs = await projectFirestore.collection('Agents').get()
            const res = docs.docs.map(doc=>doc.data()).filter(agent=>agent.uid)
            .map((agent)=>({agent_name:agent.name,agent_id:agent.id}))
            agents_names.value = res

            agents_names.value.forEach(agent=>{
                lidim.value.filter(lid=>lid.agent_id == agent.agent_id)
                .forEach(lid=>lid.agent_name = agent.agent_name)
            })
        }
        const init = async()=>{
             const docs= await projectFirestore.collection('Agent_portal').doc('Agent_portal').collection('Lidim')
             .orderBy('created_at','asc').get()
            lidim.value = docs.docs.map(doc=>doc.data())
            await get_agents_names()
        }

        const reset_extansions = (lid)=>{
            lid.created_at = new Date()
            lid.status = 'open'
            lid.agent_id=''
            delete lid.pending_string
            delete lid.pending_date
            delete lid.canceled_string
            delete lid.canceled_date
            delete lid.close_string
            delete lid.close_date
            delete lid.e_relevant_string
            delete lid.agent_name
        }

        init()
        return{
            lidim,
            lids_status_options,
            filter_by,
            search,
            pagination,
            slice_lids,
            filter_by_search,
            filter_by_status,
            handle_create_lid,
            handle_e_relevant_lid,
            platform,
            handleSortTable,
            handle_delete,
            selectedSorted,
            sorted_lids,
            handle_edit,
        }
    }
}
</script>

<style scoped>
    .lidim-manager{
        width: 100%;
        height: 100%;
    }
    .lidim-manager-tools{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .lidim-manager-content{
        width: 100%;
        height: calc(100% - 100px);
        overflow:hidden;
        overflow-y: auto;
    }
    .lidim-manager-pagination{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .back-btn{
        position: absolute;
        background: var(--danger);
        width: 30px;
        height: 30px;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 7;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    @media only screen and (max-width: 600px){
        table th {
            position: static;
        }
    }

    .mobile-table{
          margin-bottom: 5px;
    }

    
    ::v-deep(.el-pagination.is-background .btn-next i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    ::v-deep(.el-pagination.is-background .btn-prev i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    ::v-deep(.el-pagination.is-background .el-pager li:not(.disabled).active){
        background: var(--purple);
        color: #fff;
    }

    .delete-icon{
        color: crimson;
        font-size: 30px;
        cursor: pointer;
    }
    .edit-icon{
        color: var(--warning);
        font-size: 30px;
        cursor: pointer;
        margin-right: 5px;
    }
</style>