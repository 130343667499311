<template>
  <div class="lidim_per_user">
      <div class="lidim_per_user-box">
          <div class="lidim_per_user-box-header">
              <div @click="$emit('close')" class="back-btn">
                  <i class="material-icons">close</i>
              </div>
              <h2>{{agent.name}} לידים {{status}}</h2>
          </div>
        <div class="lidim_per_user-box-content">
            <template  v-for="lid in lids" :key="lid.uid">
                <AccordionLid :lid="lid" />
            </template>
        </div>
      </div>
  </div>

</template>

<script>
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import AccordionLid from './AccordionLid.vue'
export default {
    props:['lids','agent','status'],
    components:{AccordionLid},
    setup(props){

        const status = ref(computed(()=>{
            switch (props.status) {
                case 'all':
                    return 'הכל' 
                case 'close':
                    return 'סגורים' 
                case 'pending':
                    return 'ממתינים' 
                case 'open':
                    return 'פתוחים' 
                case 'canceled':
                    return 'מבוטלים' 
            }
        }))

        return{status}
    }
}
</script>

<style scoped>
    .lidim_per_user{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        z-index:8;
        color: #333;
    }
    .lidim_per_user-box{
        width: 100%;
        max-width: 500px;
        height: 90%;
        background: #fff;
        border-radius: 10px;
    }
    .lidim_per_user-box-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .back-btn{
        position: absolute;
        background: var(--danger);
        width: 30px;
        height: 30px;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 9;
        color: #fff;
    }
    .lidim_per_user-box-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow:hidden;
        overflow-y: auto;
        padding: 5px;
    }
</style>