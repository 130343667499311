<template>
  <div v-loading="is_pending" class="agents">
      <div class="agents-header">
          <h2>לוח סוכנים - לידים</h2>
          <div @click="$emit('close')" class="back-btn">
              <i class="material-icons">arrow_back</i>
          </div>
      </div>
      <div class="agents-grid">
        <template v-for="agent in all_agents" :key="agent.id">
            <div class="agent">
                <div class="agent-image">
                    <img :src="agent.profile_image" alt="">
                    <div class="agent-id">
                        {{agent.id}}
                    </div>
                    <div class="agent-name">
                        <p>{{agent.name}}</p>
                    </div>
                </div>
                <div class="agent-content">
                    <div class="agent-statuses">
                        <p class="status" @click="handle_show_lids_per_user(agent,'all')">לידים: {{agent.total_lids}}</p>
                        <p class="status" @click="handle_show_lids_per_user(agent,'close')">נסגרו: {{agent.statuses.close}}</p>
                        <p class="status" @click="handle_show_lids_per_user(agent,'canceled')">בוטלו: {{agent.statuses.canceled}}</p>
                        <p class="status" @click="handle_show_lids_per_user(agent,'pending')">ממתינים: {{agent.statuses.pending}}</p>
                        <p class="status" @click="handle_show_lids_per_user(agent,'open')">פתוחים: {{agent.statuses.open}}</p>
                    </div>
                    <div class="agent-options">
                        <el-button @click="$emit('createLid',agent)" type="primary">צור ליד</el-button>
                    </div>
                </div>
            </div>
        </template>
          
      </div>
        <LidimPerUser :lids="lids_per_user_data.lids" :status="lids_per_user_data.status" :agent="lids_per_user_data.agent"
        @close="show_lidim_per_user=false" v-if="show_lidim_per_user" />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectFirestore } from '../../../firebase/config'
import LidimPerUser from './LidimPerUser.vue'
export default {
    components:{LidimPerUser},
    setup(){
        const is_pending = ref(false)

        const show_lidim_per_user = ref(false)

        const lids_per_user_data=ref({
            status:'',
            agent:null,
            lids:[]
        })

        const all_lids = ref([])
        const all_agents = ref([])
        const handle_show_lids_per_user = (_agent,status)=>{
            lids_per_user_data.value.status = status
            lids_per_user_data.value.agent = _agent
            if(status=='all'){
                lids_per_user_data.value.lids = all_lids.value.filter(lid=>lid.agent_id==_agent.id)
            }else{
                lids_per_user_data.value.lids = all_lids.value.filter(lid=>lid.agent_id==_agent.id && lid.status==status)
            }

            show_lidim_per_user.value = true
          

        }
        const get_all_lids = async()=>{
            const docs = await projectFirestore.collection('Agent_portal').doc('Agent_portal').collection('Lidim').get()
            all_lids.value = docs.docs.map(doc=>doc.data())
        }

        const get_all_agents = async()=>{
            const docs = await projectFirestore.collection('Agents').get()
            const agents = docs.docs.map(doc=>doc.data()).filter(agent=>agent.uid)
            for(const agent of agents){
                const doc = await projectFirestore.collection('Users').doc(agent.uid).get()
                if(doc.exists){
                    agent.profile_image = doc.data().profile_image
                    agent.statuses = {
                        canceled : 0,
                        open: 0,
                        pending : 0,
                        close : 0,
                    }
                     
                    const lids_per_agent = all_lids.value.filter(lid=>lid.agent_id == agent.id)
                    agent.total_lids = lids_per_agent.length
                    if(lids_per_agent.length>0){
                        agent.statuses.canceled = lids_per_agent.filter(lid=>lid.status=='canceled').length
                        agent.statuses.open = lids_per_agent.filter(lid=>lid.status=='open').length
                        agent.statuses.pending = lids_per_agent.filter(lid=>lid.status=='pending').length
                        agent.statuses.close = lids_per_agent.filter(lid=>lid.status=='close').length
                    }
                   
                    all_agents.value.push(agent)
                }
            }
            
            
        }


    
        const init = async()=>{
            is_pending.value = true
            await get_all_lids()
            await get_all_agents()
            is_pending.value = false            
        }

        init()
        return{
            is_pending,
            all_agents,
            show_lidim_per_user,
            lids_per_user_data,
            handle_show_lids_per_user
        }
    }
}
</script>

<style scoped>
    .agents{
        position: relative;
        width: 100%;
        height: 100%;
        overflow:hidden;
        overflow-y: auto; 
        color: #fff;
    }
    .agents-header{
        position: relative;
        width: 100%;
        height: 50px;
        background: var(--secondary);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .agents-grid{
        width: 100%;
        height: calc(100% - 50px);
        overflow:hidden;
        overflow-y: auto;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-auto-rows: 300px;
        grid-gap: 5px;
        padding: 5px 0;
    }

    .agents-grid .agent{
        border-radius: 5px;
        box-shadow: 0 0 10px black;
    }
    .agent-id{
        position: absolute;
        top: 5px;
        right: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--secondary);
        font-weight: 500;
        z-index: 6;
        border-radius: 50%;
    }

    .agent-name{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--secondary);
        font-weight: 500;
        z-index: 6;
    }

    .agent-image{
        position: relative;
        width: 100%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .agent-image img{
        max-width: 100%;
        max-height: 100%;
        border-radius: 5px;
    }
    .agent-content{
        width: 100%;
        height: 40%;
        display: flex;
    }
    .agent-statuses{
        width: 50%;
        height: 100%;
        line-height: 1.3;
        padding: 5px;
    }
    .status{
        cursor: pointer;
        user-select: none;
    }
    .status:hover{
        color: var(--purple);
    }
    .agent-options{
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .back-btn{
    position: absolute;
    background: var(--danger);
    width: 30px;
    height: 30px;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 7;
    }
    @media screen and (max-width: 600px) {
        .agents-grid{
            grid-template-columns: repeat(2,1fr);
        }
    }
</style>